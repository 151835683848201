<script>
  import Button from './Button.svelte';
  import { _ } from 'svelte-i18n';
  import LanguageSelect from './LanguageSelect.svelte';
  import { page } from '$app/stores';
  import { isEnabled } from '$lib/shared/stores/language';
  
  let isLanguageEnabled;

  $: isLanguageEnabled;

  isEnabled.subscribe(val => {
    isLanguageEnabled = val;
  });

  export let menuOpen = false;
  export let systemLanguage;

  export function toggleMenu() {
    menuOpen = !menuOpen;
  }

  export function closeMenu() {
    menuOpen = false;
  }
</script>

<header class="header">
  <div class="content">
    <label for="collapsible" class="lbl-toggle" on:click={toggleMenu} />
    <a href="/" class="brand" on:click={closeMenu}>
      <img itemprop="logo" class="logo" src="/logo.svg" alt={$_('logo.alt')} />
      {$_('BokaMera')}
    </a>
    {#if true}
      <nav class="menu" class:open={menuOpen}>
        <ul>
          <li>
            <a on:click={closeMenu} href="/#pricing">{$_('menu.pricing')}</a>
          </li>
          <li>
            <a on:click={closeMenu} href="/features">{$_('menu.services')}</a>
          </li>
          <li>
            <a on:click={closeMenu} href="/demo">{$_('menu.demo')}</a>
          </li>
          <li>
            <a on:click={closeMenu} href="/faq">{$_('menu.faq')}</a>
          </li>
          <li>
            <a on:click={closeMenu} href="/about">{$_('menu.aboutUs')}</a>
          </li>
          <li>
            <a on:click={closeMenu} href="/#contact">{$_('menu.contact')}</a>
          </li>
          <li>
            <a on:click={closeMenu} href="/for-developers">
              {$_('menu.forDevelopers')}
            </a>
          </li>
        </ul>
        <a on:click={toggleMenu} class="header__button" href="/cancel-booking">
          <Button small uppercase outlined>{$_('menu.myBookings')}</Button>
        </a>
        <a
          on:click={toggleMenu}
          class="header__button"
          href="https://admin.bokamera.se/#/dashboard"
          rel="noopener"
        >
          <Button small uppercase outlined>{$_('menu.adminLogin')}</Button>
        </a>
        <a
          on:click={toggleMenu}
          class="header__button"
          href="/sign-up"
          rel="prefetch"
        >
          <Button
            {...{
              outlined: $page.url.pathname === '/sign-up'
            }}
            small
            uppercase
          >
            {$_('menu.register')}
          </Button>
        </a>
        {#if $page.url.pathname === '/payment' && isLanguageEnabled}
           <LanguageSelect systemLanguage={systemLanguage}  />
        {/if}
      </nav>
    {/if}
  </div>
</header>
<div class="dimmer" on:click={toggleMenu} class:show={menuOpen} />

<style>
  .header {
    height: 66px;
    display: flex;
    justify-content: center;
    color: #414042;
    font-size: 14px;
    box-shadow: 0 2px 4px rgb(0, 0, 0, 0.08);
    z-index: 100;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0 20px;
  }
  .lbl-toggle {
    display: none;
  }
  .content {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .menu {
    display: flex;
    align-items: center;
  }
  .brand {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 21px;
    font-family: 'Raleway', sans-serif;
  }
  .logo {
    height: 38px;
    margin-right: 9px;
  }

  .header__button {
    margin: 0 5px;
    height: 52px;
  }
  .header__button :global(button) {
    height: 100%;
  }
  .dimmer {
    display: none;
  }

  ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    align-items: center;
  }

  li {
    text-decoration: none;
    margin: 0 1em;
  }

  a,
  a:visited {
    color: #414042;
  }

  @media (max-width: 900px) {
    .header {
      height: auto;
      font-size: 20px;
      text-align: center;
      margin: 0;
      padding: 12px;
    }
    .lbl-toggle {
      display: block;
      position: absolute;
      top: 10px;
      left: 12px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      background-image: url('/menu.png');
      background-size: cover;
      background-position: center;
    }
    .menu {
      max-height: 0;
      flex-direction: column;
      overflow: hidden;
      transition: max-height 0.2s ease;
      -webkit-transition: max-height 0.2s ease;
      -moz-transition: max-height 0.2s ease;
    }
    .menu.open {
      max-height: 500px;
      transition: max-height 0.3s ease;
      -webkit-transition: max-height 0.3s ease;
      -moz-transition: max-height 0.3s ease;
    }
    .brand {
      justify-content: center;
    }
    .header__button {
      margin-bottom: 16px;
    }
    .content {
      display: block;
    }
    ul {
      display: block;
    }
    li {
      margin-bottom: 12px;
    }

    .dimmer {
      position: fixed;
      z-index: 90;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.5s ease;
      -webkit-transition: opacity 0.5s ease;
      -moz-transition: opacity 0.5s ease;
    }
    .dimmer.show {
      display: block;
      opacity: 0.4;
    }
  }
</style>
