<script context="module">
  export async function load({ session }) {
    const {
      APPINSIGHTS_INSTRUMENTATIONKEY,
      ONE_COOKIE_ID,
      DEV,
      PROD,
      LANGUAGE
    } = session;

    initI18n()

    return {
      props: {
        DEV,
        PROD,
        APPINSIGHTS_INSTRUMENTATIONKEY,
        ONE_COOKIE_ID,
        LANGUAGE
      },
    };
  }
</script>

<script>
  import { onMount, afterUpdate } from 'svelte';
  import { page } from '$app/stores';
  import Header from '../components/Header.svelte';
  import Footer from '../components/Footer.svelte';
  import { _ } from 'svelte-i18n';
  import { startAppInsights } from '../appInsights';
  import { isLoading } from 'svelte-i18n';
  import { initI18n } from '../i18n';
  import { initializeFreshwork, initGoogleTagManager, initFbScript } from '../utils/initScripts';

  export let instrumentationKey, DEV, PROD, LANGUAGE, ONE_COOKIE_ID;

  const addOnetrustScript = () => {
    if(!ONE_COOKIE_ID || document.querySelector(`[data-domain-script="${ONE_COOKIE_ID}"]`)) {
      return
    }

    const autoBlockScript = document.createElement('script')
    autoBlockScript.type = 'text/javascript';
    autoBlockScript.src = `https://cdn.cookielaw.org/consent/${ONE_COOKIE_ID}/OtAutoBlock.js`;
      

    const oneCookieScript = document.createElement('script')
    oneCookieScript.type = 'text/javascript';
    oneCookieScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    oneCookieScript.setAttribute('data-document-language', 'true');
    oneCookieScript.setAttribute('charset', 'UTF-8');
    oneCookieScript.setAttribute(
      'data-domain-script',
      ONE_COOKIE_ID
    );

    const optanonWrapperScript = document.createElement('script')
    optanonWrapperScript.type = 'text/javascript';
    optanonWrapperScript.innerHTML = 'function OptanonWrapper() { }';

    // Order matters - last comes first
    document.head.prepend(optanonWrapperScript);
    document.head.prepend(oneCookieScript);
    document.head.prepend(autoBlockScript);
  }

  onMount(async () => {
    if(!$page.url.pathname.includes('embedded')) {
      window.addEventListener('OneTrustGroupsUpdated', (ev) => {
        if(ev.detail && ev.detail.includes('C0002')){
          startAppInsights({ instrumentationKey });
          initializeFreshwork();
          initGoogleTagManager();
          initFbScript();
        }
      });
      addOnetrustScript();
    }

    window.onerror = function (msg, url, lineNo, columnNo, error) {
      console.log({ msg, url, lineNo, columnNo, error });
      return false;
    };
  });

  afterUpdate(() => {
    if (window.ga) {
      window.ga('send', 'pageview', window.location.pathname);
    }
  });
</script>

{#if $isLoading}
  Please wait...
{:else}<style>
    main {
      background-color: white;
      padding-top: 64px;
      display: block;
    }
  </style>

  {#if !$page.url.pathname.includes('embedded')}
    <Header systemLanguage={LANGUAGE} />
  {/if}

  <main>
    <slot />
  </main>

  {#if !$page.url.pathname.includes('embedded')}
    <Footer />
  {/if}

{/if}

<style global>
  @import '/node_modules/tippy.js/dist/tippy.css';
</style>